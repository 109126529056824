import React from "react"
import { graphql } from "gatsby"
import { Flex, Text, Box } from "rebass"
import { Layout, SEO } from "../components/"
import { CldImage } from "@extend"
import { GrLinkedin } from 'react-icons/gr';


const About = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Who We Are" />
      <Text as="h1" mt={'60px'} mb={10} textAlign="center">Who we are</Text>
      <Flex justifyContent={'space-between'} flexDirection={['column', 'row']}>
        <Box width={[1, .5]} p={20} >
          <Text as="h2" letterSpacing={3}>Kaja & Eliza — Food Creatives</Text>
          <Text as="h3" my={30} color="#222">Polish twins based in Madrid, Spain</Text>
          <Text as="p" textAlign='center'>
          The way we cook is inspired by our polish roots, youth years in Spain and life around the farm with our grandparents. 
          </Text>
          <Text height={'30px'} mx={'50%'} my={'10px'} sx={{ borderLeft: '1px solid #e0e0e0' }}/>
          <Text as="p" textAlign='center'>
          Everything started in our childhood with the smell of mom's buttery cakes with sweet preserves that never lasted until winter becouse of us. 
          Our memories are filled with latkes, applesauce, homemade cottage cheese and grandma's herrings that no one could ever top.
          </Text>
          <Text height={'30px'} mx={'50%'} my={'10px'} sx={{ borderLeft: '1px solid #e0e0e0' }}/>
          <Text as="p" textAlign='center'>
          We are living in a constant fear that there won't be enough food and when falling asleep we always plan what to cook the next day. 
          We are never stopping and it's never enough for us.
          </Text>
          <Text as="blockquote" textAlign='center' color="black" p={20} fontSize={30} fontFamily={'EB Garamond'}>We are obsessive and achivers. Daydreamers, lovers and learners. Wherever we appear there's always a cake with us.</Text>
          <Text as="p" textAlign='center'>
          Besides cooking, we paint, plant and shoot pictures.
          Recently, we graduated from culinary school in Madrid and finished our internship in a Michelin Star awarded restaurant called Corral de la Moreria.
          </Text>
          <Text height={'30px'} mx={'50%'} my={'10px'} mb={'10px!important'} sx={{ borderLeft: '1px solid #e0e0e0' }}/>
          <Text as="p" textAlign='center'>
          Our vision is to make the world a better place through honest food and celebrating the little (big) things. We hope to inspire and help others through sharing our recipes in this space, and to learn from you too!
          </Text>
        </Box>
        <Flex width={[1, .5]} p={60} justifyContent='center' alignItems={'start'} flexDirection='column' >
          <CldImage alt="Kaja & Eliza — Food Creatives" src='https://res.cloudinary.com/yabuko/image/upload/v1588347201/website/20200501_172510_mtmbdz.jpg' transformations={['e_blackwhite', 'c_scale', 'w_600']}></CldImage>
        </Flex>
      </Flex>

      <Flex justifyContent={'space-between'} flexDirection={['column-reverse', 'row']}>
        <Flex width={[1, .5]} p={60} justifyContent='center' alignItems={'center'} >
          <CldImage alt="Tino Gutiérrez — Digital Creative" src='https://res.cloudinary.com/yabuko/image/upload/v1588347201/website/20200501_172459_bawnop.jpg' transformations={['e_blackwhite', 'c_scale', 'w_600']}></CldImage>
        </Flex>      
      <Box width={[1, .5]} p={20} >
          <Text as="h2" letterSpacing={3}>Tino Gutiérrez — Digital Creative</Text>
          <Text as="h3" my={30} color="#222">Developer based in Madrid, Spain</Text>
          <Text as="p" textAlign='center'>
          I'm a Software Engineer with a background in Industrial Product Design, specializing in Front End Development.
          </Text>
          <Text as="blockquote" textAlign="center" color="black" p={20} fontSize={25} fontFamily={'EB Garamond'}>I help companies around the world achieve more.</Text>
          <Text as="p" textAlign='center'>
          I don't believe in one technology but instead I believe in seeking and learning the best solutions for every project.
          </Text>
          <Text height={'30px'} mx={'50%'} my={'10px'} sx={{ borderLeft: '1px solid #e0e0e0' }}/>
          <Text as="p" textAlign='center'>
          My passion is building awesome things.<br/>
          </Text>
          <Flex py='30px' justifyContent='center'>
            <a style={{ marginRight: '10px' }} target="_blank" rel="noopener noreferrer" href="http://www.linkedin.com/in/tinogutierrez/"><GrLinkedin style={{fontSize: '25px', color: '#353535', marginRight: '10px'}}/></a>
            <a className="li-link" target="_blank" rel="noopener noreferrer" href="http://www.linkedin.com/in/tinogutierrez/">linkedin.com/in/tinogutierrez/</a>
          </Flex>
        </Box>
      </Flex>
    </Layout>
  )
}

export default About
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    pictures: allFile(filter: {name: {regex: "/about/"}}) {
      edges {
        node {
          id
          publicURL
        }
      }
    }
  }
`